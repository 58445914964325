<template>
  <div class="AfterSaleManagementHome">
    <div>
      <ul>
        <li>
          <img src="../../assets/img/shgl/spjh.png" @click="jfsp" alt="">
          <p>商品交付</p>
        </li>
        <li>
          <img src="../../assets/img/shgl/sphl.png" @click="jfsp" alt="">
          <p>商品护理</p>
        </li>
        <li>
          <img src="../../assets/img/shgl/spwx.png" alt="">
          <p>商品维修</p>
        </li>
      </ul>
      <ul style="padding-top: 11vh;">
        <li>
          <img src="../../assets/img/shgl/yjkbx.png" alt="">
          <p>眼健康保险</p>
        </li>
        <li>
          <img src="../../assets/img/shgl/ybhl.png" alt="">
          <p>眼部护理</p>
        </li>
        <li @click="gos">
          <img src="../../assets/img/shgl/hygl.png" alt="">
          <p>会员管理</p>
        </li>
      </ul>
    </div>
    <el-dialog
        title="交付订单列表"
        :visible.sync="showdd"
        width="600px"
        >
      <div class="box">
        <div class="contents" v-for="(item, idx) in datas" :key="idx">
          <div class="top">
            <div>订单金额：￥{{item.COPrice}}</div>
            <p class="yjj">原价金额：￥{{item.COTotalPrice}}</p>
<!--            <p>状态：{{item.zt}}</p>-->
          </div>
          <div class="top">
            <p class="padd-100">提交时间：{{item.COAddTimeString}}</p>
            <p>订单编号：{{item.bh}}</p>
          </div>
          <div class="bottom" :class="type?'type1':''">
<!--            <el-button class="fn" @click="goxq(item.COrderId)">订单详情</el-button>-->
            <el-button class="fn" @click="spjf(item.COrderId, '/AfterSaleManagementHome/commodityJF')">交付详情</el-button>
            <el-button class="fn" @click="spjf(item.COrderId, '/AfterSaleManagementHome/commodityHl')">商品护理</el-button>
            <!--         <el-button class="fn" v-if="!type">加工单</el-button>-->
<!--            <el-button class="fn" @click="dydd(item.COrderId)">打印订单</el-button>-->
            <!--         <el-button class="fn" v-if="!type">相关咨询</el-button>-->
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Loading} from "element-ui";

export default {
  name: "AfterSaleManagementHome",
  data() {
    return {
      showdd: false,
      datas: [],
      type: ''
    }
  },
  created() {

  },
  methods: {
    go(url) {
      //
      // if (this.$store.state.bases.orid) {
      //   this.$router.push({path: url})
      // }else {
      //   this.$alert('请先选择订单', '提示')
      // }
    },
    spjf(id, url) {
      this.$store.commit('bases/upOrid', id)
      if (this.$store.state.bases.orid) {
        this.$router.push({path: url})
      }else {
        this.$alert('请先选择订单', '提示')
      }
    },

    jfsp() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      this._api.userCurdApi.getSalesList()
          .then(res => {
            loadingInstance.close();
            if (res.GetListResult && res.GetListResult.length > 0) {

              let item = []

              for(let i = 0; i< res.GetListResult.length; i++) {
                let str = ''
                if ( res.GetListResult[i].COrderType.split(',').length > 1) {
                  str = res.GetListResult[i].COrderType.split(',')[0]
                }else {
                  str = res.GetListResult[i].COrderType
                }

                if ((res.GetListResult[i].COPayState == 'partpayed' || res.GetListResult[i].COPayState == 'payed') && 'lens,rgp,frame,glasses,equipment,drug,contactglass,accessories,material'.indexOf(str) > -1) {
                  res.GetListResult[i].bh = res.GetListResult[i].COrderId.slice(0,8).toUpperCase()
                  item.push(res.GetListResult[i])
                }
              }
              // console.log(item)
              this.datas = item
            }else {
              this.datas = []
            }
            this.showdd = true
          })
          .cache(r => {
            loadingInstance.close()
          })
    },

    gos() {
      window.open(`https://b.visumall.com/#/?ExpertTel=${this.$store.state.users.employees.ExpertTel}&Password=${this.$store.state.users.employees.ExpertPWD}`)
    }
  },

}
</script>

<style scoped lang="scss">
  .AfterSaleManagementHome {
    width: 100vw;
    height: 76vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      width: 150px;
      height: 150px;
      margin-right: 2rem;
      img {
        display: inline-block;
        width: 150px;
        margin-top: 5px;
      }
    }
    li:nth-child(3) {margin: 0}
  }
  .contents {
    padding-top: 10px;
    border-bottom: 1px solid;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .contents:hover{
    background: #eeeded;
  }
  .top {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    div {font-size: 16px}
    p {
      font-size: 12px;
      color: #888787;
    }
    .padd-100 {padding-right: 0.75rem}
    .yjj {
      padding-left: 1.3rem;
      padding-right: 1.3rem;
    }
  }
  .bottom {
    display: flex;
    justify-content: start;
    .fn {
      margin-left: 0.5rem;
      &:nth-child(1) {margin: 0;}
    }
  }
  .type1 {justify-content: center;}
  .box {
    height: 50vh;
    overflow-y: auto;
  }
</style>
